<template>
  <div class="mark-tag custom-list-fields">
    <el-dialog title="打标签" :visible.sync="visible" width="500px">
      <div class="main-con">
        <div class="con-item">
          <div class="con-title">已选</div>
          <div class="chose-list-box">
            <div
              class="list-item list-item2"
              @click="removeCheckbox(item)"
              v-for="(item,index) in selectedList"
              :key="index"
            >
              {{item.labelName}}
              <i class="el-icon-delete"></i>
            </div>
          </div>
          <!-- <el-checkbox-button
            checked
            border
            v-for="(item,index) in selectedList"
            :key="index"
          >{{item.name}}</el-checkbox-button>-->
        </div>
        <div class="con-item" style="padding-top: 20px;">
          <div class="con-title">待选</div>
          <div class="chose-list-box">
            <div
              class="list-item"
              @click="selectedCheckbox(item)"
              v-for="(item,index) in choseList"
              :key="index"
            >{{item.labelName}}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmMarkTag">确定</el-button>
        <el-button @click="visible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qs from "qs";
export default {
  props: {
    userType: {
      type: Number
    }, //1是筛查2是慢病用户
    labelIds: {
      type: Array
    } //选中标签
  },
  data() {
    return {
      visible: false,
      ids: "",
      selectedList: [], //已选
      choseList: []
    };
  },
  methods: {
    init(ids) {
      let _this = this;
      _this.visible = true;
      if (_this.labelIds.length) {
        _this.selectedList = _this.labelIds;
      } else {
        _this.selectedList = [];
      }
      console.log(_this.selectedList);
      // _this.selectedList = [];
      _this.choseList = [];
      _this.getChoseList();
      _this.ids = ids;
    },
    getChoseList() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let _this = this;
      let params = {
        pageSize: 10000,
        pageNo: 1,
        userId:user.id,
        //  hospitalId: user.deptPid,
        teamsId: teamsId,
        // deptId: user.deptId,
        type:1
      };
      _this
        .$http({
          url: _this.$http.adornUrl("/mbglLabel/list"),
          method: "get",
          params: params
        })
        .then(({ data }) => {
          _this.choseList = data.data;
          if (data.data.length) {
            console.log(_this.selectedList)
            _this.selectedList.forEach((chr, chrIndex) => {
              _this.choseList.forEach((ele, index) => {
                if (ele.id == chr.id) {
                  chr.labelName = ele.labelName;
                  _this.choseList.splice(index, 1);
                }
              });
            });
          }
        });
    },
    confirmMarkTag() {
      let _this = this;
      let params = {
        ids: _this.ids.join(",")
      };
      let labelIds = "";
      if (!_this.selectedList.length) {
        _this.$message.error("请选择标签");
        return;
      } else {
        labelIds = _this.selectedList.map(item => {
          return item.id;
        });
        params.labelId = labelIds.join(",");
        // if (_this.userType == 1) {

        // } else {
        //   params.patienceLabelId = labelIds.join(",");
        // }
      }
      _this
        .$http({
          url: _this.$http.adornUrl("/teamsPatience/updateLabelByIds"),
          method: "post",
          data: qs.stringify(params)
        })
        .then(({ data }) => {
          if (data.status) {
            _this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                _this.visible = false;
                _this.$emit("refreshDataList", 2);
              }
            });
          } else {
            _this.$message.error(data.msg);
          }
        });
    },
    selectedCheckbox(val) {
      this.choseList.forEach((ele, index) => {
        if (val.id == ele.id) {
          this.choseList.splice(index, 1);
          this.selectedList.push(ele);
        }
      });
    },
    removeCheckbox(item) {
      this.selectedList.forEach((ele, index) => {
        if (item.id == ele.id) {
          this.selectedList.splice(index, 1);
          this.choseList.push(ele);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mark-tag {
  .main-con {
    padding: 10px 20px;
    .con-title {
      padding: 10px 0;
    }
    .chose-list-box {
      overflow: hidden;
      .list-item {
        padding: 10px 20px;
        border: 1px solid #dcdfe6;
        margin-left: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        float: left;
        cursor: pointer;
        &:hover {
          color: #A767FD;
        }
        &.list-item2 {
          background: #A767FD;
          color: #fff;
          .el-icon-delete {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
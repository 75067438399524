<template>
  <div class="analysis-page">
    <div style="padding-bottom: 10px;">您好，您当前正在为通用用户的{{labelInfo.labelName}}分组设置自动入组的规则</div>
    <el-form
      inline
      class="detail-info"
      v-if="labelInfo.mbglLabelIndex && labelInfo.mbglLabelIndex.id"
    >
      <el-form-item label="创建时间">{{labelInfo.createTime}}</el-form-item>
      <el-form-item label="创建人" style="padding-left: 100px;">{{labelInfo.createName}}</el-form-item>
    </el-form>
    <el-form
      inline
      class="detail-info"
      v-if="labelInfo.mbglLabelIndex && labelInfo.mbglLabelIndex.id"
    >
      <el-form-item label="更新时间">{{labelInfo.updateTime}}</el-form-item>
      <el-form-item label="更新人" style="padding-left: 100px;">{{labelInfo.updateName}}</el-form-item>
    </el-form>

    <div class="search-box" style="padding-top:20px;">
      <el-form label-width="100px">
        <el-form-item label="年龄查询">
          <el-select v-model="sex" clearable style="width:120px;">
            <el-option label="不限" value></el-option>
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
          </el-select>
          <el-input
            placeholder="请输入年龄下限"
            v-model="ageLowerLimit"
            type="number"
            style="width:200px;"
          ></el-input>
          <el-input
            placeholder="请输入年龄上限"
            v-model="ageUpperLimit"
            type="number"
            style="width:200px;"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form inline>
        <el-form-item label="选择发送日期">
          <el-date-picker
            v-model="dateTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <!-- <el-button type="primary" style="margin-left:20px;">查询</el-button> -->
        </el-form-item>
        <el-form-item label="选择完成日期">
          <el-date-picker
            v-model="dateTime2"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <!-- <el-button type="primary" style="margin-left:20px;">查询</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="patient-analysis">
      <div class="left">
        <div
          class="out-container"
          :class="[{'out-container-line marginLeft':treeList.length>1},{'out-container__active': firstIndex == index}]"
          v-for="(item,index) in treeList"
          :key="index"
          @click="clickCurrentContainer(item,index)"
        >
          <div class="out-container-cover" v-if="index == treeList.length - 1"></div>
          <div class="out-relationship-select" v-if="index < treeList.length - 1">
            <el-select style="width: 60px;" v-model="item.selectOutRelation">
              <el-option label="与" :value="1"></el-option>
              <el-option label="或" :value="2"></el-option>
            </el-select>
          </div>
          <div class="out-container-body">
            <span class="txt-cut" style="-webkit-box-orient:vertical;" :title="item.questionName">{{item.questionName?item.questionName:'未命名'}}</span>
            <span v-if="index > 0" class="delete-btn" @click.stop="deleteOutContainer(item,index)">
              <i class="el-icon-delete"></i>
            </span>
          </div>
        </div>
        <div
          class="out-container"
          :class="{'marginLeft': treeList.length>1}"
          style="border-top: none;"
          @click="addOutContainer"
        >
          <div class="out-container-body">
            <span class="add-item">+新增问卷条件</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="condition-filter">
          <div class="custom-analysis-filter">
            <div class="single-analysis">
              <el-button type="primary" @click="showchoseQuestionDialog">选择问卷</el-button>
              <!-- <span>{{questionObj.questionName}}</span> -->
              <el-button type="text" @click="toDataCollectInfo">预览问卷</el-button>
            </div>
            <div
              class="out-container2"
              :class="{'out-container-line':analysisList.length>1}"
              v-for="(secondItem,$index) in analysisList"
              :key="$index"
            >
              <div class="out-container-cover" v-if="$index == analysisList.length - 1"></div>
              <div class="in-relationship-select" v-if="$index < analysisList.length - 1">
                <el-select style="width: 60px;" v-model="secondItem.select">
                  <el-option label="与" :value="1"></el-option>
                  <el-option label="或" :value="2"></el-option>
                </el-select>
              </div>
              <div class="in-relationship-list">
                <div class="in-container-wrapper">
                  <div
                    class="in-container"
                    :class="{'in-container-line':secondItem.list.length>1}"
                    v-for="(ele,i) in secondItem.list"
                    :key="i"
                  >
                    <div class="in-container-cover" v-if="i == secondItem.list.length - 1"></div>
                    <div class="in-relationship-select" v-if="i < secondItem.list.length - 1">
                      <el-select style="width: 60px;" v-model="ele.require">
                        <el-option label="与" :value="1"></el-option>
                        <el-option label="或" :value="2"></el-option>
                      </el-select>
                    </div>
                    <div class="in-container-item">
                      <div class="item-con width30">
                        <el-select
                          class="width100"
                          placeholder="请选择问卷题目"
                          v-model="ele.questionInfo"
                          value-key="id"
                          clearable
                          filterable
                          @change="changeQuestionInfo"
                        >
                          <el-option
                            v-for="(item,index) in questionTitleList"
                            :key="index"
                            :value="item"
                            :label="item.indexName"
                          >{{item.indexName}}</el-option>
                        </el-select>
                      </div>
                      <div class="item-con width20">
                        <el-select class="width100" placeholder="请选择维度" v-model="ele.type" clearable>
                          <el-option
                            v-for="(wd,index) in wdList"
                            :key="index"
                            :value="wd.value"
                            :label="wd.name"
                          >{{wd.name}}</el-option>
                        </el-select>
                      </div>
                      <div v-if="ele.type == 1" class="compare-select">
                        <div class="item-con" style="width: 160px;">
                          <el-select placeholder="请选择比较方式" v-model="ele.sz" clearable>
                            <el-option
                              v-for="(sz,index) in szList"
                              :key="index"
                              :value="sz.value"
                              :label="sz.name"
                            >{{sz.name}}</el-option>
                          </el-select>
                        </div>
                        <div class="item-con">
                          <div
                            v-if="ele.sz == 4"
                            class="compare-select"
                          >
                            <div style="width: 160px;margin-right:10px;">
                              <el-input placeholder="请输入上限" v-model="ele.end" min="0" type="number"></el-input>
                            </div>
                            <div style="width: 160px;">
                              <el-input
                                placeholder="请输入下限"
                                v-model="ele.began"
                                min="0"
                                type="number"
                              ></el-input>
                            </div>
                          </div>
                          <div style="width: 160px;" v-if="ele.sz && ele.sz!=4">
                            <el-input placeholder="请输入数值" v-model="ele.began" min="0" type="number"></el-input>
                          </div>
                        </div>
                      </div>
                      <div
                        class="item-con"
                        style="width: 40%;"
                        v-if="ele.type == 2 && ele.questionInfo && ele.questionInfo.indexType == 1"
                      >
                        <!--  -->
                        <el-select
                          style="width: 45%;margin-right:10px;"
                          placeholder="请选择是否包含"
                          v-model="ele.bh"
                          clearable
                        >
                          <el-option :value="1" label="包含">包含</el-option>
                          <el-option :value="2" label="不包含">不包含</el-option>
                        </el-select>
                        <el-input placeholder="请输入关键字" v-model="ele.content" style="width: 50%;"></el-input>
                      </div>
                      <!-- 单选 -->
                      <div
                        class="item-con"
                        style="width: 40%;"
                        v-if="ele.type == 2 && ele.questionInfo && ele.questionInfo.indexType == 6"
                      >
                        <el-select
                          class="width100"
                          style="margin-right:10px;"
                          placeholder="请选择选项"
                          v-model="ele.content"
                          clearable
                        >
                          <el-option
                            :value="element.optionName"
                            v-for="(element,i) in ele.questionInfo.remark"
                            :key="i"
                          >{{element.optionName}}</el-option>
                        </el-select>
                      </div>
                      <!-- 多选 -->
                      <div
                        class="item-con"
                        style="width: 40%;"
                        v-if="ele.type == 2 && ele.questionInfo && ele.questionInfo.indexType == 7"
                      >
                        <el-select
                          class="width100"
                          style="margin-right:10px;"
                          placeholder="请选择选项"
                          v-model="ele.content"
                          clearable
                          multiple
                        >
                          <el-option
                            :value="element.optionName"
                            v-for="(element,i) in ele.questionInfo.remark"
                            :key="i"
                          >{{element.optionName}}</el-option>
                        </el-select>
                      </div>
                      <div>
                        <span
                          class="delete-btn"
                          @click="deleteSecondContainer($index)"
                          v-if="secondItem.list.length == 1"
                        >删除</span>
                        <span class="delete-btn" @click="deleteInContainer($index,i)" v-else>删除</span>
                      </div>
                    </div>
                  </div>
                  <!-- 新增筛选条件 -->
                  <div class="add-btn" @click="addThirdContainer($index)">+ 增加筛选条件</div>
                </div>
              </div>
            </div>
            <!-- <div class="add-btn-type2" @click="addOutContainer2">+ 增加组合条件</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="paddLeft" :class="{'paddLeft2': treeList.length>1}" style="padding-top: 30px;">
      是否启用：
      <el-radio-group v-model="radio">
        <el-radio :label="1">启用</el-radio>
        <el-radio :label="2">禁用</el-radio>
      </el-radio-group>
    </div>
    <div class="btn-group paddLeft" :class="{'paddLeft2': treeList.length>1}">
      <el-button @click="resetAnalysis">取消</el-button>
      <el-button type="primary" @click="showScreeningResultDialog">确定</el-button>
    </div>

    <!-- 选择问卷 -->
    <chose-question v-if="choseQuestionDialog" ref="choseQuestion" @setQuestionId="setQuestionId"></chose-question>
    <!-- 筛查结果 -->
    <screening-result v-if="screeningResultDialog" ref="screeningResult"></screening-result>
  </div>
</template>

<script>
import ChoseQuestion from "./components/choseQuestion";
import ScreeningResult from "./components/screeningResult";
import Qs from "qs";
export default {
  components: {
    ChoseQuestion,
    ScreeningResult
  },
  data() {
    return {
      labelId: "", //分组id
      labelInfo: {}, //分组内容
      sex: "", //年龄
      ageLowerLimit: "", //年龄下限
      ageUpperLimit: "", //年龄上限
      // 问卷名称
      questionObj: "",
      selectDateType: 1, //日期类型
      dateTime: [], //具体日期
      dateTime2: [],
      treeList: [
        {
          selectOutRelation: 1,
          questionName: "",
          analysisList: [
            {
              list: [
                {
                  require: 1
                }
              ]
            }
          ]
        }
      ],
      analysisList: [
        {
          list: [
            {
              require: 1
            }
          ]
        }
      ],
      currentLength: 1,
      firstIndex: 0, //点击新增左侧
      secondIndex: 0, //点击右侧新增白色边框遮住
      thirdIndex: 0, //点击新增筛选条件出现边框遮线
      choseQuestionDialog: false,
      screeningResultDialog: false, //筛查结果

      questionTitleList: [], //问卷题目
      wdList: [
        { value: 2, name: "明细" },
        { value: 1, name: "数值" }

        // { value: 3, name: "内容" }
      ], //维度列表
      szList: [
        { value: 1, name: "大于" },
        { value: 2, name: "小于" },
        { value: 3, name: "等于" },
        { value: 4, name: "介于" }
      ], //数值列表
      radio: 2
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.labelId = this.$route.query.id;
      this.getAutoGroupInfo();
    }
  },
  methods: {
    // 预览问卷
    toDataCollectInfo() {
      let _this = this;
      if (!_this.questionObj.questionNameId) {
        _this.$message.error("请选择问卷名称");
        return;
      }
      let href =
        this.$global.dominH5 +
        "dataCollectPreview2.html?id=" +
        _this.questionObj.questionNameId;
      window.open(href);
    },
    //点击当前的未命名问卷
    clickCurrentContainer(item, index) {
      let _this = this;
      _this.firstIndex = index;
      _this.analysisList = _this.treeList[index].analysisList;
      // _this.secondIndex = _this.analysisList.length - 1;
      console.log(item);

      if (item && item.questionNameId) {
        _this.questionObj.questionNameId = item.questionNameId;
        _this.getQustionTitleList(item);
      } else {
        _this.questionTitleList = [];
      }
    },
    //   添加未命名
    addOutContainer(index) {
      let _this = this;
      _this.treeList.push({
        selectOutRelation: 1,
        analysisList: [
          {
            list: [
              {
                require: 1
              }
            ]
          }
        ]
      });
      _this.firstIndex = _this.treeList.length - 1;
      if (_this.firstIndex - 1 == 0) {
        _this.treeList[_this.firstIndex - 1].analysisList = JSON.parse(
          JSON.stringify(_this.analysisList)
        );
      }
      _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
      _this.secondIndex = _this.analysisList.length - 1;
      _this.currentLength = _this.treeList.length;
      _this.questionObj = {};
      _this.getQustionTitleList();
      console.log(_this.treeList);
    },
    // 删除未命名
    deleteOutContainer(item, index) {
      let _this = this;
      _this.treeList.splice(index, 1);
      _this.firstIndex = _this.treeList.length - 1;
      _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
      console.log("delete", index);
      if (
        _this.treeList[_this.firstIndex] &&
        _this.treeList[_this.firstIndex].questionNameId
      ) {
        _this.questionObj.questionNameId =
          _this.treeList[_this.firstIndex].questionNameId;
        _this.getQustionTitleList(item);
      } else {
        _this.questionTitleList = [];
      }
    },
    // 追加右边组合条件
    addOutContainer2() {
      let _this = this;
      _this.treeList[_this.firstIndex].analysisList.push({ list: [{}] });
      _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
      _this.secondIndex = _this.analysisList.length - 1;
    },
    // 删除组合条件
    deleteSecondContainer(index) {
      let _this = this;
      // _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
      if (_this.analysisList.length == 1) {
        _this.$message.info("已经到第一个了");
      } else {
        _this.treeList[_this.firstIndex].analysisList.splice(index, 1);
        _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
        _this.secondIndex = _this.analysisList.length - 1;
      }
    },
    // 新增内部筛选条件
    addThirdContainer($index) {
      let _this = this;
      _this.analysisList[$index].list.push({
        require: 1
      });
      // _this.analysisList = JSON.stringify(JSON.parse(_this.analysisList));
      _this.thirdIndex = _this.analysisList[$index].list.length - 1;
      console.log(_this.thirdIndex);
    },
    // 删除内部筛选条件
    deleteInContainer($index, i) {
      let _this = this;
      _this.analysisList[$index].list.splice(i, 1);
      // _this.thirdIndex =  _this.analysisList[_this.secondIndex].list.length - 1;
    },
    // 重置
    resetAnalysis() {
      this.$router.push({ name: "groupList" });
    },
    // 问卷题目选中情况
    changeQuestionInfo(item) {
      console.log(123, item);
      if (item.indexType == 6 || item.indexType == 7) {
        item.remark = JSON.parse(item.remark);
      }
    },
    // 选择问卷显示
    showchoseQuestionDialog() {
      let _this = this;
      _this.choseQuestionDialog = true;
      _this.$nextTick(() => {
        _this.$refs.choseQuestion.init();
      });
    },
    //确定选中问卷
    setQuestionId(value) {
      let _this = this;
      _this.questionObj = value;
      // _this.analysisList.forEach(ele => {
      //   ele.list.forEach(chr => {
      //     if (chr.indexId) {
      //       chr.indexId = "";
      //     }
      //   });
      // });

      _this.getQustionTitleList();
      _this.$set(
        _this.treeList[_this.firstIndex],
        "questionName",
        _this.questionObj.questionName
      );
      _this.$set(
        _this.treeList[_this.firstIndex],
        "questionNameId",
        _this.questionObj.questionNameId
      );
    },
    //筛查
    showScreeningResultDialog() {
      let _this = this;
      if (!_this.questionObj.questionNameId) {
        _this.$message.error("请选择问卷名称");
        return;
      }

      let isNull = true;
      let arr = [];
      let teamsId = "";
      let enertyId = "";
      let libraryId = [];
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      if (_this.treeList.length == 1) {
        _this.$set(
          _this.treeList[0],
          "analysisList",
          JSON.parse(JSON.stringify(_this.analysisList))
        );
      }
      // console.log(_this.treeList);
      _this.treeList.forEach((item, $index) => {
        libraryId.push(item.questionNameId);
        let obj = {
          libraryId: item.questionNameId,
          libraryName: item.questionName,
          type: 1,
          teamsId: teamsId,
          require: item.selectOutRelation,
          data: []
        };
        item.analysisList[0].list.forEach((element, index) => {
          element.indexId = "";
          if (element.questionInfo && element.questionInfo.id) {
            element.indexId = element.questionInfo.id;
          }
          if (!element.indexId || !element.type) {
            isNull = false;
            return isNull;
          }
          if (element.type == 1) {
            if (!element.sz) {
              isNull = false;
              return isNull;
            } else {
              if (element.sz == 3 || element.sz == 1 || element.sz == 2) {
                element.end = element.began;
              }
              if (!(element.began && element.end)) {
                isNull = false;
                return isNull;
              }
            }
          } else {
            if (!element.content) {
              isNull = false;
              return isNull;
            }
            if (element.questionInfo && element.questionInfo.indexType == 7) {
              element.content = JSON.parse(JSON.stringify(element.content));
            }
          }
          obj.data.push(element);
        });
        arr.push(obj);
      });
      // console.log(arr);
      // return;
      if (isNull) {
        // let newArr = JSON.parse(JSON.stringify(arr));
        // newArr.forEach((ele)=>{
        //   ele.data.forEach((chr)=>{
        //     _this.$delete(chr,"questionInfo");
        //   })
        // })
        // _this.screeningResultDialog = true;

        // let json = JSON.stringify(newArr);
        // _this.$nextTick(() => {
        //   _this.$refs.screeningResult.init(json);
        // });
        let params = {
          labelId: _this.labelId,
          libraryId: libraryId.join(","),
          content: JSON.stringify(arr),
          sex: _this.sex,
          ageLowerLimit: _this.ageLowerLimit,
          ageUpperLimit: _this.ageUpperLimit,
          sendStartTime: _this.dateTime.length ? _this.dateTime[0] : "",
          sendEndTime: _this.dateTime.length ? _this.dateTime[1] : "",
          finishStartTime: _this.dateTime2.length ? _this.dateTime2[0] : "",
          finishEndTime: _this.dateTime2.length ? _this.dateTime2[1] : "",
          isGrouping: _this.radio
        };

        let isEdit = false;
        if (
          _this.labelInfo.mbglLabelIndex &&
          _this.labelInfo.mbglLabelIndex.id
        ) {
          isEdit = true;
          params.id = _this.labelInfo.mbglLabelIndex.id;
        }
        console.log(params);
        // return;
        _this
          .$http({
            url: _this.$http.adornUrl(
              isEdit ? "/mbglLabelIndex/update" : "/mbglLabelIndex/add"
            ),
            method: "post",
            data: Qs.stringify(params)
          })
          .then(({ data }) => {
            if (data.status) {
              _this.editGroup();
            } else {
              _this.$message.error(data.msg);
            }
          });
      } else {
        _this.$message.error("请完善所有条件筛选的项目");
      }
    },
    // 编辑分组内容，设置是否自动分组
    editGroup() {
      let params = JSON.parse(JSON.stringify(this.labelInfo));
      // if (params.mbglLabelIndex && params.mbglLabelIndex.id) {
      //   this.$delete(params, "mbglLabelIndex");
      // }
      this.$delete(params, "mbglLabelIndex");
      params.isGrouping = this.radio;
      this.$http({
        url: this.$http.adornUrl("/mbglLabel/update"),
        method: "post",
        data: Qs.stringify(params)
      }).then(({ data }) => {
        if (data.status) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.$router.push({ name: "groupList" });
            }
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },

    // 根据筛查条件设置分组
    getAutoGroupInfo() {
      let _this = this;
      let params = {
        id: _this.labelId
      };
      _this
        .$http({
          url: _this.$http.adornUrl("/mbglLabel/info"),
          method: "get",
          params: params
        })
        .then(({ data }) => {
          if (data.status) {
            _this.labelInfo = data.data;
            if (
              _this.labelInfo.mbglLabelIndex &&
              _this.labelInfo.mbglLabelIndex.content
            ) {
              _this.treeList = [];
              if (_this.labelInfo.mbglLabelIndex.sex) {
                _this.sex = _this.labelInfo.mbglLabelIndex.sex;
              }
              _this.ageLowerLimit =
                _this.labelInfo.mbglLabelIndex.ageLowerLimit;
              _this.ageUpperLimit =
                _this.labelInfo.mbglLabelIndex.ageUpperLimit;
              if (_this.labelInfo.mbglLabelIndex.sendStartTime) {
                _this.dateTime = [
                  _this.labelInfo.mbglLabelIndex.sendStartTime,
                  _this.labelInfo.mbglLabelIndex.sendEndTime
                ];
              }
              if (_this.labelInfo.mbglLabelIndex.finishStartTime) {
                _this.dateTime2 = [
                  _this.labelInfo.mbglLabelIndex.finishStartTime,
                  _this.labelInfo.mbglLabelIndex.finishEndTime
                ];
              }
              if (_this.labelInfo.isGrouping) {
                _this.radio = _this.labelInfo.isGrouping;
              } else {
                _this.radio = 2;
              }
              let content = JSON.parse(_this.labelInfo.mbglLabelIndex.content);
              console.log(content);
              content.forEach((ele, index) => {
                let obj = {
                  questionNameId: ele.libraryId,
                  questionName: ele.libraryName,
                  selectOutRelation: ele.require
                };
                obj.analysisList = [
                  {
                    list: []
                  }
                ];
                obj.analysisList[0].list = ele.data;
                _this.treeList.push(obj);
              });
              if (_this.treeList.length) {
                _this.questionObj = {
                  questionNameId: _this.treeList[0].questionNameId,
                  questionName: _this.treeList[0].questionName
                };
                _this.getQustionTitleList();
                _this.analysisList = _this.treeList[0].analysisList;
              }

              // console.log("123", _this.analysisList);
            }
          }
        });
    },
    //根据问卷名称id获取问卷下的题目
    getQustionTitleList(item) {
      let _this = this;
      let params = {
        pageSize: 10000,
        pageNo: 1,
        libraryId: _this.questionObj.questionNameId
      };
      if (item && item.questionId) {
        params.libraryId = item.questionId;
      }
      console.log(params.libraryId);
      if (params.libraryId) {
        _this
          .$http({
            url: _this.$http.adornUrl("/indexInfo/list"),
            method: "get",
            params: params
          })
          .then(({ data }) => {
            if (data.status) {
              _this.questionTitleList = data.data;
            }
          });
      } else {
        _this.questionTitleList = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.patient-analysis {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  .out-container {
    // margin-left: 100px;
    transition: all 0.5s;
    padding: 10px 15px;
    border: 1px solid #e3e3e3;
    height: 70px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-top: none;
    &.marginLeft {
      margin-left: 100px;
    }

    &:first-child {
      border-top: 1px solid #e3e3e3;
    }

    .out-relationship-select {
      position: absolute;
      left: -90px;
      top: 50%;
      margin-top: 20px;
    }
    .out-container-body {
      display: flex;
      align-items: center;
      // justify-content: center;
      .add-item {
        color: #A767FD;
      }
      .delete-btn {
        font-size: 16px;
        color: #666 !important;
        position: absolute;
        right: 10px;
        padding-left: 10px;
        cursor: pointer;
      }
    }
    &.out-container__active {
      border: 1px solid #A767FD !important;
      border-right: none !important;
      background: #f7f9ff;
      position: relative;
    }
  }

  .out-container-line {
    position: relative;
    box-sizing: border-box;
    .out-container-cover {
      position: absolute;
      top: 50%;
      left: -60px;
      width: 5px;
      height: 1000px;
      background-color: #fff;
      margin-top: 1px;
    }
    &::before {
      content: "";
      position: absolute;
      left: -60px;
      top: 50%;
      bottom: -1000px;
      border-left: 1.5px solid #e0e0e0;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: -60px;
      width: 60px;
      border-bottom: 1.5px solid #e0e0e0;
      margin-top: -0.5px;
    }
  }

  .right {
    flex: 1;
    .condition-filter {
      height: 100%;
      .custom-analysis-filter {
        height: 100%;
        position: relative;
        overflow: hidden;
        background: #f6f9ff;
        border: 1px solid #A767FD;
        padding: 0 10px 30px 30px;
        .single-analysis {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 30px 10px 14px 0;
        }
        .out-container2 {
          //   width: 100%;
          background-color: #fff;
          padding: 10px 15px;
          border: 1px solid #e3e3e3;
          margin-top: 15px;
          .out-container-cover {
            position: absolute;
            top: 50%;
            left: -60px;
            width: 5px;
            height: 1000px;
            background-color: #f6f9ff;
            margin-top: 1px;
          }
          .in-relationship-select {
            position: absolute;
            left: -90px;
            top: 50%;
            margin-top: 10px;
          }
          .in-relationship-list {
            overflow: hidden;
            .in-container-wrapper {
              .in-container {
                background-color: #fff;
                padding: 10px 0;
                padding-left: 10px;
                position: relative;

                .in-container-item {
                  display: flex;
                  align-items: center;
                  .item-con {
                    margin-right: 10px;
                    .delete-btn {
                      font-size: 16px;
                      color: #666 !important;
                      cursor: pointer;
                      padding-left: 10px;
                    }
                  }
                }
              }
              .in-container-line {
                position: relative;
                margin-left: 100px;
                &::before {
                  content: "";
                  position: absolute;
                  left: -60px;
                  top: 50%;
                  bottom: -1000px;
                  border-left: 1.5px solid #d6d6d6;
                }
                &::after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: -60px;
                  width: 60px;
                  border-bottom: 1.5px solid #d6d6d6;
                  margin-top: -0.5px;
                }
                .in-container-cover {
                  position: absolute;
                  top: 50%;
                  left: -60px;
                  width: 5px;
                  height: 1000px;
                  background-color: #fff;
                  margin-top: 1px;
                }
              }
              .add-btn {
                margin: 20px 60px 8px 60px;
                border: 1px dashed #A767FD;
                color: #A767FD;
                line-height: 35px;
                font-size: 14px;
                text-align: center;
                cursor: pointer;
                border-radius: 6px;
              }
            }
          }
        }
        .add-btn-type2 {
          margin: 15px 0;
          border: 1px dashed #d6d6d6;
          color: #999;
          line-height: 60px;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
          border-radius: 6px;
          background-color: #fff;
        }
      }
    }
  }
}
.btn-group {
  padding: 30px 0;
}
.compare-select {
  display: flex;
  align-items: center;
}
.delete-btn {
  cursor: pointer;
  color: #A767FD;
}
.detail-info {
  .el-form-item {
    margin-bottom: 0;
  }
}
.paddLeft {
  padding-left: 200px;
  &.paddLeft2 {
    padding-left: 300px;
  }
}
.width20 {
  width: 20%;
}
.width30 {
  width: 30%;
}
.width100 {
  width: 100%;
}
</style>